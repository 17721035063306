<template>
  <div class="about">
    <h1>About me and my passion</h1>
    <p></p>
    <h3>Curriculum vitae</h3>
    <h2>PHP Engineer (2013 - now)</h2>
    <div class="row m-0">
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="php-testing"
          :build="true"
          build-name="Testing"
          :coverage-code="true"
          :coverage-mutation="true"
          :php="true"
          :php-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="php-thread"
          :build="true"
          build-name="Testing"
          :coverage-code="true"
          :coverage-mutation="true"
          :php="true"
          :php-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="BrowserDetection.js"
          :build="true"
          :coverage-code="true"
          :coverage-mutation="true"
          :npm="true"
          :npm-logo="true"
          npm-name="browser-detection.js"
          :js-badges="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="CSSSassCollection"
          npm-name="csssasscollection"
          :build="true"
          :npm-logo="true"
          :npm="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="CSSLessCollection"
          :npm-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="generator-typo3"
          :tag="false"
          :npm-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="generator-html5-page"
          :tag="false"
          :npm-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="generator-vagrant"
          :tag="false"
          :npm-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="FitText.js"
          :tag="false"
        ></GitHubSourceCode>
      </div>
    </div>
    <h2>Master of Science (M. Sc.) Big Data & Business Analytics (2018 - 2021)</h2>
    <div class="row m-0">
      <div class="col-md-6 col-12">
        <GitHubDataSet
          name="COVID-19-Dataset"
          label="COVID-19 Dataset"
          :python-logo="true"
        ></GitHubDataSet>
      </div>
      <div class="col-md-6">
        <GitHubDataSet
          name="COVID-19-RKI"
          label="COVID-19 Robert Koch-Institut Dataset"
          :python-logo="true"
        ></GitHubDataSet>
        <!-- <GitHubDataSet
          name="FOM-Big-Data-Green-Tea"
          label="FOM Big Data Green Tea"
        ></GitHubDataSet>-->
      </div>
    </div>
    <h2>Bachelor of Science (B. Sc.) Business Informatics (2014 - 2018)</h2>
    <div class="row m-0 pb-5">
      <div class="col-12">
          <GitHubSourceCode
            name="FOM-LaTeX-Template"
            :tag="false"
          ></GitHubSourceCode>
      </div>
    </div>
    <h2>Training for IT specialists in the field of system integration (2010 - 2013)</h2>
    <div class="row m-0">
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="GitHooks"
          :tag="false"
          :license="true"
          :bash-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="GitJobs"
          :tag="false"
          :license="true"
          :bash-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="SyncDB.sh"
          :license="true"
          :bash-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="MACOUI2MySQL"
          :license="true"
          :tag="false"
          :python-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="NetworkExplorer.NET"
          :license="true"
          :tag="false"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="Battleship.NET"
          :license="true"
          :tag="false"
        ></GitHubSourceCode>
      </div>
    </div>
    <h2>other time before ...</h2>
    <div class="row m-0 pb-5">
      <div class="col-12">
        <GitHubSourceCode
          name="UserScripts-Eggdrop"
          :license="true"
          :tag="false"
        ></GitHubSourceCode>
      </div>
    </div>
  </div>
</template>

<script>
import GitHubSourceCode from '@/components/GitHubSourceCode';
import GitHubDataSet from '@/components/GitHubDataSet';
export default {
  name: 'ViewAbout',
  components: {
    GitHubDataSet,
    GitHubSourceCode
  }
}
</script>
